import React, { useLayoutEffect } from 'react';
import '../css/custom-form.css';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';

export interface ICustomFormProps {
    children?: any;
}

const CustomForm = (props: ICustomFormProps) => {
    const configCustomForm = getConfigFrontEnd({ name: 'CustomForm' });
    console.log(configCustomForm);
    useLayoutEffect(() => {
        const script = document.createElement('script');
        script.src = configCustomForm;
        script.async = true;

        document.body.appendChild(script);
        return () => {};
    }, []);

    return <div className="_form_3" />;
};

export default CustomForm;
